
/* -------  APP Styles -------------------- */

::-webkit-scrollbar {
  width: 0px;
}

html, body {
  scrollbar-width: none;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(219, 211, 164));
}

.app-container {
  display: block;
}

a {
  text-decoration: none;
  color: black;
}
/* ---------   NAVBAR Styles --------------- */

.nav-container {
  width: 100%;
  margin-bottom: 5%;
}

.nav-container li a {
  color: black;
}

.nav-container ul {
  text-decoration: none;
  list-style: none;
  padding-inline-start: 0px;
  text-align: center;
  align-items: center;
}

.nav-container h2 {
  font-family: 'Kalam', cursive;
  font-size: 1em;
  margin-block-end: 0px;
}

.nav-container span {
  font-size: 50px;
}

.nav-container li {
  padding-bottom: 15px;
  font-size: 48px;
  font-family: 'Kalam', cursive;
  cursor: pointer;
  display: inline-block;
  margin: 0 auto;
  margin-left: 30px;
  margin-right: 30px;
}

.nav-container li h3 {
  font-weight: normal;
  margin: 0px;
}

.nav-container li h3:hover {
  opacity: 0.6;
}

.nav-header {
  width: 100%;
  height: 300px;
}

@media screen and (max-width: 1024px) {
  .nav-header {
    height: 300px;
  }  

  .nav-container li {
    padding-bottom: 10px;
    font-size: 36px;
    margin-left: 18px;
    margin-right: 18px;
  }
}

@media screen and (max-width: 480px) {
  .nav-header {
    height: 120px;
  }  

  .nav-container li {
    padding-bottom: 5px;
    font-size: 26px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    border-bottom: 1px solid grey;
  }
}

.submenu {
  position: absolute;
  height: 0px;
  overflow: hidden;
}

#submenu-care {
  max-height: 200px;
}

#submenu-about {
  max-height: 150px;
}

.submenu-slideout {
    height: 250px;
    transition:             height 300ms ease;
        -moz-transition:    height 300ms ease;
        -ms-transition:     height 300ms ease;
        -o-transition:      height 300ms ease;
        -webkit-transition: height 300ms ease;
        border: 1px solid black;
        border-radius: 8px;
    z-index: 2;
}

.submenu-slidein {
  height: 0px;
    transition:             height 300ms ease;
        -moz-transition:    height 300ms ease;
        -ms-transition:     height 300ms ease;
        -o-transition:      height 300ms ease;
        -webkit-transition: height 300ms ease;
    border-radius: 8px;
}

.submenu ul {
  list-style-position: inside;
  background-color: rgb(255, 255, 255);
}

.submenu li {
  font-size: 16px;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
}

.submenu li:hover {
  font-weight: bold;
}

/* ------------ FOOTER Styles --------------------------- */

.footer-container {
  width: 100%;
  border-radius: 6px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(219, 211, 164));
  margin: 0 auto;
}

.site-map-container {
  width: 50%;
  margin: 0 auto;
}

.footer-container ul {
  margin: 0 auto;
  margin-top: 20px;
  padding-inline-start: 0px;
  padding-top: 5%;
  padding-bottom: 5%;
}

.copyright-notice {
  color: rgb(131, 126, 126);
  font-size: 16px;
  text-align: center;
}

.site-map {
  display: flex;
  margin: 0 auto;
}

.site-map ul {
  list-style: none;
}

.site-map li {
  padding: 10px;
  font-size: 18px;
}

.site-map li:hover {
  opacity: 0.6;
}

.site-map-mobile {
  display: flex;
  margin: 0 auto;
  padding-top: 5px;
}

.site-map-mobile h5 {
  margin: 0 auto;
  color: black;
}

.site-map-mobile ul {
  list-style: none;
  margin-top: 5%;
}

@media screen and (max-width: 1024px) {

  .copyright-notice {
    top: 80px;
    font-size: 18px;
  }

.site-map-container {
    width: 100%;
    font-size: 20px;
  }

  .site-map li {
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
 
   .copyright-notice {
    top: 40px;
    font-size: 12px;
  }

  .site-map {
    margin-top: 20%;
  }

  .site-map ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0px;
    text-align: left;
  }

  .footer-ul-right li {
    font-size: 35px;
    text-align: center;
    border-bottom: 1px solid grey;
  }
  
  .footer-ul-right a {
    font-size: 22px;
  }

  .site-map-mobile {
    width: 90%;
  }

  .site-map-container {
    width: 100%;
  }

  .site-map li {
    padding: 0px;
  }

  .site-map-mobile li {
    padding-bottom: 5px;
  }
  
  .site-map-mobile a {
    padding: 6px;
    font-size: 16px;
    text-align: left;
    font-weight: lighter;
  }    
}


/* ---------------  BUILD LOG Styles --------------------------------------- */

.build-log {
  width: 90%;
  margin: 0 auto;
  max-height: 0px;
  background-color: white;
}

.build-log-open {
  border: 2px solid black;
  border-radius: 6px;
  max-height: 3000vh;
}

/* ---------------------- BUILD LOG PAGES ------------------------------- */


/* IDEAS & PLANNING */

.build-log-page img {
  width: 40%;
  margin: 0 auto;
}

.build-log-page h1 {
  margin-top: 5%;
  font-size: 1.5em;
  background-color: rgb(105, 156, 105);
  border-radius: 6px;
}

.build-log-page {
  text-align: center;
  font-family: 'Architects Daughter', cursive;
  font-size: 2em;
  padding: 5%;
}

.build-log-page h3 {
  margin-top: 5%;
  text-decoration: underline;
  font-size: 1.5em;
}

.build-log-page ul {
  list-style: none;
  padding-inline-start: 0px;
}

.build-log-page li {
  font-size: 20px;
  margin: 0 auto;
}

.buildlog-quick-links {
  display: flex;
}

.buildlog-quick-links li:hover {
  cursor: pointer;
  font-style: italic;
}

.buildlog-quick-links li {
  font-family: 'Yusei Magic', sans-serif;
  font-size: 18px;
}

.buildlog-quick-links li a {
  color: rgb(150, 150, 150);
}

.selected {
  text-decoration: underline;
}

.selected:hover { 
 font-style: normal !important;
}

.ideas-planning-content {

  width: 100%;
  margin: 0 auto;
  font-size: 1em;
}

@media screen and (max-width: 1024px) {
  .build-log-page img {
    width: 50%;
  }

  .build-log-page h1 {
    font-size: 2em;
  }

  .buildlog-quick-links li {
    font-size: 22px;
  }
}

@media screen and (max-width: 480px) {

  .build-log-page img {
    width: 100%;
  }
    
  .build-log-page {
    font-size: 1em;
  }  

  .build-log-page h1 {
    font-size: 2em;
  }

  .buildlog-quick-links {
    width: 100%;
    padding-inline-start: 0px;
    display: block;
  }

  .buildlog-quick-links li {
    font-size: 14px;
    margin-bottom: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
}

/* TOOLS AND MATERIALS  ////////////////////////////////////////////*/

.tools-materials-content {

  width: 100%;
  margin: 0 auto;
  font-size: 1em;
}

.tools-materials-content h3 {
  text-decoration: underline;
}

.tools-materials-table {
  width: 98%;
  border: 3px solid grey;
  border-radius: 6px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 5%;
}

.tools-materials-table h4 {
  text-decoration: underline;
}

.tools-table-list {
  width: 40%;
  border-right: 1px solid gray;
  background-color: rgb(255, 236, 209);
}

.materials-table-list {
  width: 60%;
  border-right: 1px solid rgb(195, 255, 240);
  background-color: #ddffbd;
}

.tools-table-list ul {
  display: block;
  padding-inline-start: 0px;
}

.materials-table-list ul {
  display: block;
  padding-inline-start: 0px;
}

.tools-table-list li {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
}

.materials-table-list li {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
}

@media screen and (max-width: 480px) {
  .tools-materials-content {
    width: 100%;
  }

  .tools-materials-table {
    width: 98%;
    display: block;
  }

  .tools-table-list {
    width: 100%;
  }

  .materials-table-list {
    width: 100%;
  }

  .materials-table-list li, .tools-table-list li {
    font-size: 20px;
  }

  .materials-table-list h4, .tools-table-list h4 {
    font-size: 24px;
  }

}


/* GETTING TO WORK          ////////////////////////////////////*/


.getting-to-work-content {
  margin: 0 auto;
  font-size: 1em;
}

.getting-to-work-content p {
  width: 100%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

.getting-to-work-content h3 {
  text-decoration: underline;
}

.getting-to-work-content img {
  width: 90%; 
  cursor: zoom-in;
}

.getting-to-work-content a {
  margin: 0 auto;
  width: 60%;
}

#cad-box-specs-img {
  width: 40%;
  cursor: default;
}

.wood-rail-list {
  border: 1px solid grey;
  border-radius: 8px;
  width: 30%;
  margin-left: 2%;
}

.wood-rail-list h4 {
  text-decoration: underline;
  font-size: 22px;
}

.wood-rail-list ul {
  display: block;
  padding-inline-start: 0px;
}

.wood-rail-list li {
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 20px;
}

.woodrails-image-content {
  width: 100%;
  display: flex;
}

.spec-images img {
  width: 60%;
  padding-top: 5%;
  cursor: default;
}

.spec-images {
 background-color: #dad8d4;
 margin-right: 0px;
 overflow: hidden;
 width: 68%;
 border-radius: 8px;
}

.spec-image-btns li {
  border-radius: 8px;
  background-color: white;
  padding: 15px;
  width: 20%;
  font-weight: bolder;
  cursor: pointer;
}

.spec-image-btns ul {
  display: flex;
  padding-inline-start: 0px;
}

.spec-image-btn {
  background-color: white;
  border: 3px solid white;
  margin: 0 auto;
}

.spec-image-btns li:hover {
  background-color:rgb(91, 230, 255);
}

.spec-image-selected {
  border: 3px solid rgb(91, 230, 255) !important;
  pointer-events: none;
}

.wood-cut-imgs {
  display: flex;
}

.image-caption {                     
  font-size: 20px;
  margin-top: 0px;
  margin-right: 0px;
  color: grey;
  font-style: italic;
}

@media screen and (max-width: 1024px) {
  .image-caption {
    font-size: 0.8em;
  }
  
  #cad-box-specs-img {
    width: 80%;
}
}

@media screen and (max-width: 480px) {
  .image-caption {
    font-size: 14px;
  }
  
  #cad-box-specs-img {
    width: 100%;
  }
}

.dry-fit-imgs img{

  width: 40%;
  margin: 20px;
}

.window-panes-list li {
  margin-top: 20px;
  font-size: 22px;
}

.window-panes-list {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid grey;
  border-radius: 8px;
}

.window-panes-list ul {
  display: block;
}

@media screen and (max-width: 1024px) {
  
  .spec-image-btns li {
    width: 15%;
  }

  .window-panes-list {
    width: 90%;
  }

  .wood-rail-list {
    width: 40%;
    margin-left: 0%;
  }
}

@media screen and (max-width: 480px) {
  .getting-to-work-content img {
    width: 100%; 
  }

  .wood-rail-list {
    width: 100%;
    margin: 0 auto;
    border: none;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin-bottom: 50px;
  }

  .wood-cut-imgs {
    display: block;
  }

  .woodrails-image-content {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  .spec-images {
    width: 100%;
  }

  .spec-image-btns ul {
    padding-inline-start: 0px;
  }
  .spec-image-btns li {
    font-size: 16px;
    font-weight: bold;
    padding: 8px;
  }

  .window-panes-list {
    width: 96%;
    text-align: center;
    padding: 0px;
    border: none;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin-bottom: 30px;
  }

  .dry-fit-imgs img {
    margin: 0px;
  }
}

/*  PUTTING EVERTHING TOGETHER      **********************************/

.putting-together-content {
  margin: 0 auto;
  font-size: 1em;
}

.putting-together-content p {
  width: 100%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

.putting-together-content h3 {
  text-decoration: underline;
}

.putting-together-content img {
  width: 90%; 
  cursor: zoom-in;
}

.putting-together-content a {
  margin: 0 auto;
  width: 60%;
}

.asm-imgs {
  display: flex;
  padding-bottom: 0px;
}

@media screen and (max-width: 480px) {
  .asm-imgs {
    display: block;
  }
}

/* FINISHING TOUCHES ******** */

.finishing-content {
  margin: 0 auto;
  font-size: 1em;
  width: 100%;
}

.finish-imgs {
  display: flex;
  padding-bottom: 0px;
  cursor: zoom-in;
}

@media screen and (max-width: 480px) {
  .finish-imgs {
    display: block;
  }
}

/* END OF BUILD PAGE BUTTONS */

.page-end-btn-container {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  margin: 0 auto;
  margin-top: 5%;
  font-size: 22px;
  color: grey;
  font-family: 'Times New Roman', Times, serif;
}

.page-end-btn-container a {
  color: rgb(43, 83, 28);
}

#page-end-prev {
  margin: 0 auto;
  border: 1px solid grey;
  border-radius: 8px;
  padding: 5px;
  padding-top: 8px;
  background-color: rgb(204, 237, 241);
  cursor: pointer;
}

#page-end-top {
  margin: 0 auto;
  border: 1px solid grey;
  border-radius: 8px;
  padding: 5px;
  padding-top: 8px;
  background-color: lavenderblush;
  cursor: pointer;
}

#page-end-next {
  margin: 0 auto;
  border: 1px solid grey;
  border-radius: 8px;
  padding: 5px;
  padding-top: 8px;
  background-color: rgb(219, 238, 210);
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .page-end-btn-container {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .page-end-btn-container {
    font-size: 14px;
  }

  .page-end-btn-container div {
    width: 85px;
  }
}


/* ------------------ Slide Gallery ------------------------- */

#slide-frame {
  width: 40%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

#slide-frame img {
  width: 100%;
  margin: 0 auto;
}

.slide-fadeout {
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;

}

.slide-fadein {
  opacity: 1.0;
  -webkit-transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -ms-transition: opacity 2s ease-in-out;
  -o-transition: opacity 2s ease-in-out;
}

@media screen and (max-width: 1024px) {
  #slide-frame {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  #slide-frame {
    width: 100%;
  }
}

/* -------------------- HAMSTER CARE Styles ----------------------------- */

.hamstercare-content {
 width : 80%;
 border: 3px solid grey;
 border-radius: 6px;
 padding: 5%;
 margin: 0 auto;
 font-size: 26px;
 font-family: 'Architects Daughter', cursive;
 background-color: white;
}

.hamstercare-content h6 {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 20px;
}

.hamstercare-quick-links li:hover {
  cursor: pointer;
  font-weight: bolder;
}

.hamstercare-quick-links {
 display: flex;
 list-style: none;
 padding-inline-start: 0px;
 width: 80%;
 margin-left: 10%;
}

.hamstercare-quick-links li {
 margin: 0 auto;
 font-family: 'Yusei Magic', sans-serif;
 font-size: 18px;
 color: rgb(150, 150, 150);
}

.hamstercare-disc p {
  font-size : 16px;
}

.hamstercare-content h2 {
  text-align: center;
  background-color: rgb(230, 230, 38);
  border-radius: 6px;
}

.hamstercare-disc {
  font-size : 18px;
  padding: 2%;
}

.hab-env-ul {
  margin-bottom: 10%;
}

.hab-env-ul li {
  padding-top: 15px;  
}

#sleepy-ham-img {
  width: 300px;
  height: 300px;
  float: right;
  border-radius: 8px;
}

#hammy-donut {
  width: 25%;
  height: 50%;
  float: right;
  border-radius: 8px;
}

#hammy-img {
  width: 25%;
  height: 50%;
  float: left;
  border-radius: 8px;
  margin-right: 40px;
}

.hamstercare-content h3 {
  border-bottom: 1px solid black;
  width: 50%;
}

.hamster-foods-table {
  width: 80%;
  border: 3px solid grey;
  border-radius: 6px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 5%;
}

.good-foods-list {
  width: 50%;
  border-right: 1px solid gray;
  background-color: rgb(226, 226, 226);
}

.bad-foods-list {
  width: 50%;
  background-color: rgb(236, 214, 214);
}
.good-foods-list h4 {
  text-align: center;
  text-decoration: underline;
}

.bad-foods-list h4 {
  text-align: center;
  text-decoration: underline;
}

#dietnut-title, #grooming-title {
  margin-top: 10%;
}

#safe-text {
  color: rgb(139, 211, 31);
}

#bad-text {
  color: red;
}

.top-of-page-btn {
  width: 180px;
  height: 40px;
  color: rgb(114, 116, 112);
  border: 1px solid black;
  border-radius: 6px;
  padding: 5px;
  margin: 0 auto;
  padding-top: 15px;
  text-align: center;
  cursor: pointer;
  background-color:rgb(201, 226, 162);
  font-family: 'Times New Roman', Times, serif;
  font-size: 22px;
}

.topbtn-sm {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 20px;
  padding-top: 0px;
}

@media screen and (max-width: 1024px) {
  .hamstercare-content {
    width : 90%;
    border: 3px solid grey;
    border-radius: 6px;
    padding: 5%;
    margin: 0 auto;
    font-size: 30px;
    font-family: 'Architects Daughter', cursive;
   }

   .hamstercare-content h2 {
    font-size: 2em;
  }

  .hamstercare-quick-links li {
    font-size: 22px;
  }

   .hamstercare-content h3 {
    font-size: 48px;
    width: 100%;
  }

   .hamstercare-disc p {
    font-size : 22px;
  }

  .hamster-foods-table {
    width: 90%;
  }

  .hamster-foods-table h4 {
    font-size: 28px;
  }
}

@media screen and (max-width: 480px) {

  .hamstercare-content {
    border: none;
  }

  .hamstercare-content h2 {
    font-size: 1.5em;
  }

  .hamstercare-quick-links {
    display: block;
  }
  .hamstercare-quick-links li {
    text-align: center;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: bisque;
  }

  .hamstercare-disc p {
    font-size : 16px;
  }

  .hamstercare-content h3 {
    font-size: 24px;
    width: 100%; 
  }

  .hab-env-ul {
    padding: 0px;
    margin-bottom: 5%;
    padding-inline-start: 0px;
  }
  
  .hab-env-ul li {
    padding-top: 10px;
    font-size: 16px;  
  }
  #sleepy-ham-img, #hammy-donut, #hammy-img {
    width: 100%;
    margin-bottom: 15px;
  }

  .hamster-foods-table {
    width: 100%;
  }

  .hamster-foods-table h4 {
    font-size: 16px;
  }
  
  .hamster-foods-table ul {
    padding-inline-start: 20px;
  }

  .hamster-foods-table li {
    font-size: 14px;
  }
  
.top-of-page-btn {
    width: 120px;
    height: 20px;
    padding: 5px;
    padding-top: 5px;
    font-size: 16px;
  }
}


/* ----------------------- ABOUT Styles --------------------------------- */

.about-content {
  width: 60%;
  border: 3px solid grey;
  border-radius: 6px;
  margin: 0 auto;
  padding: 5%;
  font-size: 26px;
  background-color: white;
}

.about-content h2 {
  font-family: 'Architects Daughter', cursive;
}

.about-content p {
  font-family: 'Architects Daughter', cursive;
}

#about-gizzy-img, #about-hamster-img {
  width: 50%;
  height: 50%;
  border-radius: 8px;
}

#about-hamster-img {
  margin-left: 25%;
}

.gizzy-pic-container {
  display: flex;
}

.gizzy-pic-container p {
  
  font-size: 22px;
  margin-left: 5%;
  margin-top: 10%;
}

@media screen and (max-width: 1024px) {
  .about-content {
    width: 80%;
    border: 3px solid grey;
    border-radius: 6px;
    margin: 0 auto;
    font-size: 28px;
  }

  .gizzy-pic-container p {
    font-size: 26px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 480px) {
  .about-content {
    width: 90%;
    border: none;
    margin: 0 auto;
    font-size: 18px;
  }

  .gizzy-pic-container {
    display: block;
  }

  .gizzy-pic-container p {
    font-size: 14px;
    margin-top: 0px;
  }

  #about-gizzy-img, #about-hamster-img {
    width: 100%;
    margin: 0 auto;
  }
}

/* ------------------- HOME CONTENT *****************/

.home-content {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5%;
  font-size: 24px;
}

.home-content p {
  width: 60%;
  margin: 0 auto;
  line-height: 1.5em;
  font-family: 'Architects Daughter', cursive;
}

.home-content iframe {
  margin-top: 3%;
}

.download-label {
  text-align: center;
  border: none;
  color: rgb(243, 105, 51);
  border-radius: 6px;
  font-size: 28px;
  font-style: italic;
  padding-top: 0px;
  margin: 0 auto;
  margin-top: 5%;
}

.download-container {
  margin-top: 25px;
  margin-bottom: 5%;
}

.download-container p {
  font-size: 18px;
  padding-bottom: 10px;
}

#sub-btn {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 120px;
  height: 36px;
  font-size: 18px;
  border: none;
  border-radius: 6px;
  outline: none;
  color: white;
  background-color: rgb(158, 228, 54);
}

#sub-btn:hover {
  transition: border 1s ease; 
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  opacity: 0.6;
  background-color: rgb(146, 236, 62);
}

.download-container input {
  height: 28px;
  font-size: 20px;
  width: 25%;
  border: none;
  border-radius: 6px;
  font-weight: normal;
  outline: none;
  margin-bottom: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.hidden {
  position: absolute;
  left: -5000px;
}

.quote-text {
  font-size: 24px;
  font-style: italic;
  font-weight: bold;
  padding-bottom: 50px;
  font-family: 'Kalam', cursive;
}

@media screen and (max-width: 480px) {
  #mce-EMAIL {
    width: 80%;
  }

  .home-content iframe {
    width: 100%;
  }

  .home-content p {
    width: 96%;
    font-size: 16px;
  }
  .quote-text {
    font-size: 18px;
   
    padding-bottom: 30px;
    
  }
  
}

/**********   THANK YOU PAGE **************** */

.signup-confirmation {
  width: 60%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 3%;
}

.signup-confirmation p {
  color: rgb(155, 132, 0);
  font-size: 24px;
  margin-bottom: 30px;
}

.download-btn {
  height: 30px;
  width: 160px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 auto;
  font-size: 26px;
  padding: 5px;
  padding-top: 6px;
  background-color: rgb(133, 189, 111);
  color: rgb(248, 233, 233);
  font-weight: bold;
}

.download-btn:hover {
  background-color: rgb(130, 219, 142);
}

/*******   CONTACT FORM **************** */

.contact-form-content {
  text-align: center;
  margin-bottom: 8%;
}

.contact-form-content h2 {
  font-size: 2em;
  color:rgb(80, 83, 83);
  font-family: 'Kalam', cursive;
}

.contact-form-container {
  width: 40%;
  border: 1px solid grey;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
  padding-bottom: 3%;
  color:rgb(204, 237, 241);
  background-color: teal;
}

.contact-form-container label {
  padding-left: 12px;
}

.contact-form-item {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  text-align: left;
}

.contact-form-item label {
  text-align: left;
  font-size: 24px;
}

.contact-form-item input {
  display: block;
}

.contact-form-container input {

  margin-top: 4px;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 28px;
  border: none;
  border-radius: 6px;
  outline: none;
  width: 92%;
}

textarea {
  margin-top: 4px;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 100px;
  border: none;
  border-radius: 6px;
  outline: none;
  width: 92%;
}

#send-msg-btn {
  height: 30px;
  width: 50%;
  font-weight: bold;
}

#send-msg-btn:hover {
  opacity: 0.6;
  cursor: pointer;
}

.contact-success-msg {
  margin: 0 auto;
}

.contact-success-msg p {
  font-size: 2em;
  color:rgb(155, 132, 0);
}

.contact-success-msg a {
  font-size: 2em;
  font-family: 'Times New Roman', Times, serif;
  color:rgb(61, 126, 58);
  border: 3px solid rgb(62, 141, 128);
  border-radius: 8px;
  padding: 5px;
}

@media screen and (max-width:480px) {

  .contact-form-container {
    width: 100%;
    border: 1px solid grey;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
    padding-bottom: 3%;
    color:rgb(204, 237, 241);
    background-color: teal;
  }

  .contact-form-content h2 {
    font-size: 1.5em;
    color:rgb(80, 83, 83);
    font-family: Arial, Helvetica, sans-serif;
  }
}

/* GALLERY  */

.photo-gallery-content {
  margin: 0 auto;
  text-align: center;
  width: 80%;
  font-family: 'Architects Daughter', cursive;
  margin-bottom: 5%;
}

.photo-container {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1em;
}

.photo-container img {
  width: 240px;
  height: 160px;
  border-radius: 6px;
}

@media screen and (max-width: 480px) {
  .photo-container {
    display: block;
  }

  .photo-container img {
    margin-bottom: 25px;
  }
}


